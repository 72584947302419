import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/


export function store(data) {
  return request({
    url: '/profile/change-password',
    method: 'post',
    data
  })
}
export function storeTransactionPassword(data) {
  return request({
    url: '/profile/transaction-password',
    method: 'post',
    data
  })
}




